<template>
  <div class="d-flex">
    <p class="empty">
      „
    </p>
    <blockquote class="blockquote">
      
        <slot />
      
    </blockquote>
  </div>
</template>

<script>
export default {
  name: 'Citation'
}
</script>

<style scoped>
p.empty {
  width: 200px;
  height: 0;
  overflow: visible;
  color: rgba(00, 00, 00, 0.3);
  font-size: 200px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
    'Lucida Sans', Arial, sans-serif;
  position: relative;
  bottom: 200px;
}
</style>
